import StoryChief from '@/storychief';

export const ASSISTANT_URL_SEARCH_PARAMS = {
  initCommand: 'initCommand',
  workingTitle: 'workingTitle',
};

export const COMMAND_IDENTIFIERS = {
  CHAT: 'chat',
  EDITORIAL_BRIEF: 'editorial-brief',
  EXPAND: 'expand',
  SUMMARIZE: 'summarize',
  PARAPHRASE: 'paraphrase',
  TRANSLATE: 'translate',
  SOCIAL_POST: 'social-post',
  TOPIC_QUESTIONS: 'topic-questions',
  TOPIC_PROSCONS: 'topic-proscons',
  TOPIC_BLOG_TITLES: 'topic-blog-titles',
  SPELLING: 'spelling',
  EXPLAIN: 'explain',
  SIMPLIFY: 'simplify',
  PRESS_RELEASE: 'press-release',
  JOB_DESCRIPTION: 'job-description',
  CHANGE_TONE: 'change-tone',
  BLOG_POST: 'blog-post',
  WEBSITE_CONTENT: 'webpage',
  NEWSLETTER: 'newsletter',
  VIDEO_PROJECT: 'video',
  EBOOK: 'ebook',
  WEBINAR: 'webinar',
  PODCAST: 'podcast',
  STORY_EXCERPT: 'story-excerpt',
  STORY_META_DESCRIPTION: 'story-meta-description',
  STORY_META_TITLE: 'story-meta-title',
  LANGUAGE: 'language',
  TONE: 'tone',
  POST_TYPE: 'post_type',
  OUTPUT_TYPE: 'output_type',
  AUDIENCE: 'audience',
};

export const COMMAND_SECTIONS = {
  EDIT: 'edit',
  DRAFT: 'draft',
  WRITE: 'write',
  CHAT: 'chat',
};

export const COMMAND_LANGUAGES = [
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'English', value: 'english', code: 'en' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'French', value: 'french', code: 'fr' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Spanish', value: 'spanish', code: 'es' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'German', value: 'german', code: 'de' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Dutch', value: 'dutch', code: 'nl' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Italian', value: 'italian', code: 'it' },
  {
    identifier: COMMAND_IDENTIFIERS.LANGUAGE,
    label: 'Portuguese',
    value: 'portuguese',
    code: 'pt',
  },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Danish', value: 'danish', code: 'da' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Finnish', value: 'finnish', code: 'fi' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Norwegian', value: 'norwegian', code: 'no' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Swedish', value: 'swedish', code: 'sv' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Hindi', value: 'hindi', code: 'hi' },
  {
    identifier: COMMAND_IDENTIFIERS.LANGUAGE,
    label: 'Indonesian',
    value: 'indonesian',
    code: 'id',
  },
  {
    identifier: COMMAND_IDENTIFIERS.LANGUAGE,
    label: 'Mandarin',
    value: 'mandarin',
    code: 'zh-CN',
  },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Thai', value: 'thai', code: 'th' },
  {
    identifier: COMMAND_IDENTIFIERS.LANGUAGE,
    label: 'Vietnamese',
    value: 'vietnamese',
    code: 'vi',
  },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Japanese', value: 'japanese', code: 'ja' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Korean', value: 'korean', code: 'ko' },
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Slovak', value: 'slovak', code: 'sk' },
];

export const COMMAND_LANGUAGES_WITH_AUTODETECT = [
  { identifier: COMMAND_IDENTIFIERS.LANGUAGE, label: 'Autodetect', value: '' },
  ...COMMAND_LANGUAGES,
];

const COMMAND_TONES = [
  { identifier: COMMAND_IDENTIFIERS.TONE, label: '💼 Professional', value: 'professional' },
  { identifier: COMMAND_IDENTIFIERS.TONE, label: '🧢 Casual', value: 'casual' },
  { identifier: COMMAND_IDENTIFIERS.TONE, label: '🎯 Straightforward', value: 'straightforward' },
  { identifier: COMMAND_IDENTIFIERS.TONE, label: '💪 Confident', value: 'confident' },
  { identifier: COMMAND_IDENTIFIERS.TONE, label: '😂 Humorous', value: 'humorous' },
];

const OUTPUT_TYPES = [
  {
    label: 'Full text',
    value: '',
  },
  {
    label: 'Outline',
    value: 'outline',
  },
];

export const COMMAND_TONES_WITH_AUTODETECT = [
  { identifier: COMMAND_IDENTIFIERS.TONE, label: 'Autodetect', value: '' },
  ...COMMAND_TONES,
];

const BLOG_POST_TYPES = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'How-to guide',
    value: 'how_to_guide',
    label_description:
      'Step-by-step posts with clear instructions on how to complete a task or solve a problem.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Listicle',
    value: 'listicle',
    label_description: 'Numbered list posts, such as "10 Ways to Boost Your Productivity".',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Case study',
    value: 'case_study',
    label_description:
      'Example-based posts to illustrate a larger point or concept, often featuring a problem and its solution.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Interview',
    value: 'interview',
    label_description:
      'Q&A posts where the blogger interviews an expert or thought leader in their field.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Opinion piece',
    value: 'opinion_piece',
    label_description:
      'Personal posts where the blogger shares their thoughts and ideas on a particular topic.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'News story',
    value: 'news_story',
    label_description:
      'Posts reporting on current events or developments in a particular industry or field.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Roundup',
    value: 'roundup',
    label_description:
      'Posts summarizing content from other sources, such as news articles, blog posts, or social media.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Product update',
    value: 'product_update',
    label_description:
      'Posts announcing and providing details about updates or new releases of a particular product or service.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Contest or giveaway',
    value: 'contest_or_giveaway',
    label_description:
      'Posts promoting contests or giveaways, typically in exchange for social media engagement or other actions.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Event recap',
    value: 'event_recap',
    label_description:
      'Posts recapping and sharing insights from events, such as conferences or webinars, that the blogger attended.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Research summary',
    value: 'research_summary',
    label_description:
      'Posts summarizing and discussing academic or scientific research studies, providing insights and commentary for a general audience.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Best practice',
    value: 'best_practice',
    label_description:
      'Posts providing guidelines and tips on how to approach a particular task or problem, based on industry best practices.',
  },
];

const SOCIAL_POST_PURPOSES = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Information sharing',
    value: 'information_sharing',
    label_description:
      'Share news, updates, or important information with followers or the public.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Personal expression',
    value: 'personal_expression',
    label_description:
      'Share thoughts, opinions, experiences, or emotions with friends, family, or followers.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Entertainment',
    value: 'entertainment',
    label_description:
      'Share funny, amusing, or interesting content to engage and entertain users.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Networking',
    value: 'networking',
    label_description:
      'To connect and interact with like-minded individuals, professionals, or potential collaborators.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Promotion',
    value: 'promotion',
    label_description: 'Promote a product, service, or event to potential customers or attendees.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Brand building',
    value: 'brand_building',
    label_description:
      "Increase brand visibility, establish a brand's voice, or create a specific image in users' minds.",
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Education',
    value: 'education',
    label_description:
      'Share useful, informative content that teaches or explains a topic, concept, or skill.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Advocacy',
    value: 'advocacy',
    label_description: 'Raise awareness, support, or funds for a cause or social issue.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Customer support',
    value: 'customer_support',
    label_description:
      'Address customer questions, concerns, or complaints and provide assistance or solutions.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Community building',
    value: 'community_building',
    label_description:
      'Foster a sense of belonging, create a shared identity, or facilitate discussions among users who share common interests.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'User-generated content',
    value: 'user_generated_content',
    label_description:
      'Encourage and showcase content created by users, often for the purpose of engagement, feedback, or social proof.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Collaboration',
    value: 'collaboration',
    label_description:
      'Work together with other users or organizations on joint projects, campaigns, or events.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Contests and giveaway',
    value: 'contest_and_giveaway',
    label_description:
      'Encourage user engagement or generate buzz by offering prizes or rewards for participation.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Crisis management',
    value: 'crisis_management',
    label_description:
      "To address and manage negative situations or public relations issues that may affect a brand or individual's reputation.",
  },
];

const WEBSITE_CONTENT_POST_TYPE = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Homepage',
    value: 'homepage',
    label_description:
      'A webpage that is the main entry point of a website, providing an overview and navigation options.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Product page',
    value: 'product page',
    label_description:
      'A webpage that is the main entry point of a website, providing an overview and navigation options.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Service page',
    value: 'service page',
    label_description:
      'Webpage outlining and explaining the services offered by a business or organization.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Landing page',
    value: 'landing page',
    label_description:
      'Standalone webpage for a specific marketing or advertising campaign, aimed at prompting a particular action.',
  },
];

const NEWSLETTER_POST_TYPE = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Company update',
    value: 'company update',
    label_description:
      'Focused on sharing recent developments, news, and updates about the company.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Product launch',
    value: 'product launch',
    label_description: 'Announcing the introduction of new products, features, or services.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Curated content',
    value: 'curated content',
    label_description:
      "Presenting carefully selected and organized content, often from various sources, catering to the audience's interests.",
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Event',
    value: 'event',
    label_description: 'Informing subscribers about upcoming events, conferences, or gatherings.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Roundup',
    value: 'roundup',
    label_description:
      'Summarizing and highlighting key information or noteworthy content from a specific period.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Digest',
    value: 'digest',
    label_description:
      'Providing a condensed summary or compilation of relevant information, often in a digestible format.',
  },
];

const VIDEO_PROJECT_POST_TYPE = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Explainer video',
    value: 'explainer video',
    label_description: 'Explain a product, service, or concept in a concise and engaging manner.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Product demonstration',
    value: 'product demonstration',
    label_description: 'Showcase how a product works, highlight its features and benefits.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Testimonials and case study',
    value: 'testimonials and case study',
    label_description:
      'Feature satisfied customers or clients sharing their positive experiences with a product or service to build trust and credibility.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'How-to and tutorial Video',
    value: 'how-to and tutorial video',
    label_description: 'Demonstrate how to use a product or solve a problem.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Social media video',
    value: 'social media video',
    label_description:
      'Short, engaging videos tailored for specific social media platforms and designed to capture attention quickly.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Ad campaign',
    value: 'ad campaign',
    label_description: 'Video ads to promote products or services.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Interviews and Q&A session',
    value: 'interviews and q&a session',
    label_description:
      'Feature industry experts, influencers, or company leaders in interviews or Q&A sessions to add credibility and provide valuable insights.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Brand story video',
    value: 'brand story video',
    label_description:
      'Narratives that tell the story of a brand, its mission, and values to create emotional bond with your audience.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Video content plan',
    value: 'video content plan',
    label_description:
      'Create a full YouTube content plan, with video ideas, keywords, SEO, and more.',
  },
];

const EBOOK_POST_TYPE = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Educational Ebook',
    value: 'educational ebook',
    label_description:
      'Provide in-depth information about a specific topic, industry, or skill. Educate the audience and position the brand as an expert in the field.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'How-to guides and tutorial',
    value: 'how-to guides and tutorial',
    label_description:
      'Walk readers through a process or teach them how to do something. These ebooks are practical and offer actionable insights.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Case study',
    value: 'case study',
    label_description:
      'Highlight real-world examples of successful projects, implementations, or solutions.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'White paper',
    value: 'white paper',
    label_description:
      'In-depth, authoritative reports that delve into complex subjects. Use whitepapers to showcase thought leadership, research findings, or industry insights.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Product guide',
    value: 'product guide',
    label_description:
      'Detailing the features, benefits, and applications of a product and help potential customers understand the value proposition to make informed decisions.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Industry report',
    value: 'industry report',
    label_description:
      'Provide a comprehensive analysis and insights into a specific industry or market trends. Industry reports can position a company as a thought leader and a go-to resource for industry information.',
  },
];

const WEBINAR_POST_TYPE = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Educational webinar',
    value: 'educational webinar',
    label_description:
      'Focus on providing valuable information and insights on a specific topic or industry. ',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Product demo',
    value: 'product demo',
    label_description:
      'Showcase the features and benefits of a product or service. Product demos allow marketers to give a live demonstration, answer questions, and address concerns directly.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Training and onboarding Webinar',
    value: 'training and onboarding webinar',
    label_description:
      'Provide an educational session for users to understand how to use a product or service effectively. This will increase customer retention and satisfaction.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Expert Interview',
    value: 'expert interview',
    label_description:
      'Expert interviews add credibility to the content and attracts an audience interested in the insights of the guest speaker.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Panel Discussion',
    value: 'panel discussion',
    label_description:
      'Bring together a group of experts or professionals to discuss a specific topic or industry trends. ',
  },
];

const PODCAST_POST_TYPE = [
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Autodetect',
    value: 'autodetect',
    label_description:
      'Our AI assistant will try to detect the purpose of your post based on the content you provide.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Interview podcast',
    value: 'interview podcast',
    label_description: 'Feature interviews with industry experts, influencers, or thought leaders.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Educational podcast',
    value: 'educational podcast',
    label_description:
      'Provide valuable insights, tips, and information on specific topics within the industry.',
  },
  {
    identifier: COMMAND_IDENTIFIERS.POST_TYPE,
    label: 'Product spotlight',
    value: 'product spotlight',
    label_description:
      'Highlight specific products or services, discussing their features, use cases, and benefits.',
  },
];

export const COMMANDS_TYPE = {
  CHAT: 'CHAT',
  EXPAND: 'EXPAND',
  BLOG_POST: 'BLOG_POST',
  WEBSITE_CONTENT: 'WEBSITE_CONTENT',
  NEWSLETTER: 'NEWSLETTER',
  VIDEO_PROJECT: 'VIDEO_PROJECT',
  EBOOK: 'EBOOK',
  WEBINAR: 'WEBINAR',
  PODCAST: 'PODCAST',
  PRESS_RELEASE: 'PRESS_RELEASE',
  JOB_DESCRIPTION: 'JOB_DESCRIPTION',
  EDITORIAL_BRIEF: 'EDITORIAL_BRIEF',
  SUMMARIZE: 'SUMMARIZE',
  TRANSLATE: 'TRANSLATE',
  PARAPHRASE: 'PARAPHRASE',
  SOCIAL_POST: 'SOCIAL_POST',
  TOPIC_QUESTIONS: 'TOPIC_QUESTIONS',
  TOPIC_PROSCONS: 'TOPIC_PROSCONS',
  TOPIC_BLOG_TITLES: 'TOPIC_BLOG_TITLES',
  SPELLING: 'SPELLING',
  CHANGE_TONE: 'CHANGE_TONE',
  EXPLAIN: 'EXPLAIN',
  SIMPLIFY: 'SIMPLIFY',
  STORY_EXCERPT: 'STORY_EXCERPT',
  STORY_META_DESCRIPTION: 'STORY_META_DESCRIPTION',
  STORY_META_TITLE: 'STORY_META_TITLE',
};

export const COMMANDS = {
  [COMMANDS_TYPE.CHAT]: {
    section: COMMAND_SECTIONS.CHAT,
    title: 'Chat with assistant',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-magic-crystal-ball-50.svg'),
    description: '',
    identifier: COMMAND_IDENTIFIERS.CHAT,
    slug: 'chat',
    input: {
      label: 'Ask assistant anything...',
      labelSelectedText: 'Ask assistant to edit or generate...',
      type: 'text',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
  },
  [COMMANDS_TYPE.EXPAND]: {
    section: COMMAND_SECTIONS.WRITE,
    title: 'Continue writing',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-enlarge-50.svg'),
    description: 'Let our assistant continue where you are blocked.',
    identifier: COMMAND_IDENTIFIERS.EXPAND,
    slug: 'content-expand',
    autoRun: true,
    input: {
      label: 'Content',
      type: 'text',
    },
  },
  [COMMANDS_TYPE.BLOG_POST]: {
    contentTypes: ['Story'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Blog post',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
    description: 'Generate a blog post',
    identifier: COMMAND_IDENTIFIERS.BLOG_POST,
    slug: 'blog-post',
    items: [
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Autodetect',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'autodetect' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'How-to guide',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'how_to_guide' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Listicle',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'listicle' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Case study',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'case_study' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Interview',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'interview' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Opinion piece',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'opinion_piece' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'News story',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'news_story' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Roundup',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'roundup' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Product update',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'product_update' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Contest or giveaway',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'contest_or_giveaway' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Event recap',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'event_recap' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Research summary',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'research_summary' } },
      },
      {
        command: COMMAND_IDENTIFIERS.BLOG_POST,
        title: 'Best practice',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'best_practice' } },
      },
      {
        command: COMMAND_IDENTIFIERS.PRESS_RELEASE,
        title: 'Press release',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-commercial-50.svg'),
        value: {},
      },
      {
        command: COMMAND_IDENTIFIERS.JOB_DESCRIPTION,
        title: 'Job posting',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-resume-50.svg'),
        value: {},
      },
    ],
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Post type',
        identifier: COMMAND_IDENTIFIERS.POST_TYPE,
        type: 'select',
        values: BLOG_POST_TYPES,
      },
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
  },
  [COMMANDS_TYPE.WEBSITE_CONTENT]: {
    contentTypes: ['WebsiteContent'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Webpage',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-website-content-50.svg'),
    description: 'Generate website content',
    identifier: COMMAND_IDENTIFIERS.WEBSITE_CONTENT,
    slug: 'webpage',
    items: [
      {
        command: COMMAND_IDENTIFIERS.WEBSITE_CONTENT,
        title: 'Autodetect',
        icon: StoryChief.asset(
          'images/components/assistant/commands/icons8-website-content-50.svg',
        ),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'autodetect' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBSITE_CONTENT,
        title: 'Homepage',
        icon: StoryChief.asset(
          'images/components/assistant/commands/icons8-website-content-50.svg',
        ),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'homepage' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBSITE_CONTENT,
        title: 'Product page',
        icon: StoryChief.asset(
          'images/components/assistant/commands/icons8-website-content-50.svg',
        ),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'product page' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBSITE_CONTENT,
        title: 'Service page',
        icon: StoryChief.asset(
          'images/components/assistant/commands/icons8-website-content-50.svg',
        ),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'service page' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBSITE_CONTENT,
        title: 'Landing page',
        icon: StoryChief.asset(
          'images/components/assistant/commands/icons8-website-content-50.svg',
        ),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'landing page' } },
      },
    ],
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Page type',
        identifier: 'post_type',
        type: 'select',
        values: WEBSITE_CONTENT_POST_TYPE,
      },
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
  },
  [COMMANDS_TYPE.NEWSLETTER]: {
    contentTypes: ['Newsletter'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Newsletter',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
    description: 'Generate newsletter',
    identifier: COMMAND_IDENTIFIERS.NEWSLETTER,
    slug: 'newsletter',
    items: [
      {
        command: COMMAND_IDENTIFIERS.NEWSLETTER,
        title: 'Autodetect',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'autodetect' } },
      },
      {
        command: COMMAND_IDENTIFIERS.NEWSLETTER,
        title: 'Company update',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'company update' } },
      },
      {
        command: COMMAND_IDENTIFIERS.NEWSLETTER,
        title: 'Product launch',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'product launch' } },
      },
      {
        command: COMMAND_IDENTIFIERS.NEWSLETTER,
        title: 'Curated content',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'curated content' } },
      },
      {
        command: COMMAND_IDENTIFIERS.NEWSLETTER,
        title: 'Event',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'event' } },
      },
      {
        command: COMMAND_IDENTIFIERS.NEWSLETTER,
        title: 'Roundup',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'roundup' } },
      },
      {
        command: COMMAND_IDENTIFIERS.NEWSLETTER,
        title: 'Digest',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-newsletter-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'digest' } },
      },
    ],
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Newsletter type',
        identifier: COMMAND_IDENTIFIERS.POST_TYPE,
        type: 'select',
        values: NEWSLETTER_POST_TYPE,
      },
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
  },
  [COMMANDS_TYPE.VIDEO_PROJECT]: {
    contentTypes: ['VideoProject'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Video',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
    description: 'Generate video script',
    identifier: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
    slug: 'video',
    items: [
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Autodetect',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'autodetect' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Explainer video',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'explainer video' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Product demonstration',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'product demonstration' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Testimonials and case study',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'testimonials and case study' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'How-to and tutorial Video',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'how-to and tutorial video' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Social media video',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'social media video' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Ad campaign',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'ad campaign' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Interviews and Q&A session',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'interviews and q&a session' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Brand story video',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'brand story video' } },
      },
      {
        command: COMMAND_IDENTIFIERS.VIDEO_PROJECT,
        title: 'Video content plan',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-video-project-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'video content plan' } },
      },
    ],
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Video type',
        identifier: COMMAND_IDENTIFIERS.POST_TYPE,
        type: 'select',
        values: VIDEO_PROJECT_POST_TYPE,
      },
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
  },
  [COMMANDS_TYPE.EBOOK]: {
    contentTypes: ['Ebook'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Ebook',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
    description: 'Generate ebook',
    identifier: COMMAND_IDENTIFIERS.EBOOK,
    slug: 'ebook',
    items: [
      {
        command: COMMAND_IDENTIFIERS.EBOOK,
        title: 'Autodetect',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'autodetect' } },
      },
      {
        command: COMMAND_IDENTIFIERS.EBOOK,
        title: 'Educational Ebook',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'educational ebook' } },
      },
      {
        command: COMMAND_IDENTIFIERS.EBOOK,
        title: 'How-to guides and tutorial',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'how-to guides and tutorial' } },
      },
      {
        command: COMMAND_IDENTIFIERS.EBOOK,
        title: 'Case study',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'case study' } },
      },
      {
        command: COMMAND_IDENTIFIERS.EBOOK,
        title: 'White paper',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'white paper' } },
      },
      {
        command: COMMAND_IDENTIFIERS.EBOOK,
        title: 'Product guide',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'product guide' } },
      },
      {
        command: COMMAND_IDENTIFIERS.EBOOK,
        title: 'Industry report',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-ebook-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'industry report' } },
      },
    ],
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Ebook type',
        identifier: COMMAND_IDENTIFIERS.POST_TYPE,
        type: 'select',
        values: EBOOK_POST_TYPE,
      },
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
  },
  [COMMANDS_TYPE.WEBINAR]: {
    contentTypes: ['Webinar'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Webinar',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-webinar-50.svg'),
    description: 'Generate webinar script',
    identifier: COMMAND_IDENTIFIERS.WEBINAR,
    slug: 'webinar',
    items: [
      {
        command: COMMAND_IDENTIFIERS.WEBINAR,
        title: 'Autodetect',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-webinar-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'autodetect' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBINAR,
        title: 'Educational webinar',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-webinar-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'educational webinar' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBINAR,
        title: 'Product demo',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-webinar-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'product demo' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBINAR,
        title: 'Training and onboarding Webinar',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-webinar-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'training and onboarding webinar' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBINAR,
        title: 'Expert Interview',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-webinar-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'expert interview' } },
      },
      {
        command: COMMAND_IDENTIFIERS.WEBINAR,
        title: 'Panel Discussion',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-webinar-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'panel discussion' } },
      },
    ],
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Webinar type',
        identifier: COMMAND_IDENTIFIERS.POST_TYPE,
        type: 'select',
        values: WEBINAR_POST_TYPE,
      },
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
  },
  [COMMANDS_TYPE.PODCAST]: {
    contentTypes: ['Podcast'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Podcast',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-podcast-50.svg'),
    description: 'Generate podcast script',
    identifier: COMMAND_IDENTIFIERS.PODCAST,
    slug: 'podcast',
    items: [
      {
        command: COMMAND_IDENTIFIERS.PODCAST,
        title: 'Autodetect',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-podcast-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'autodetect' } },
      },
      {
        command: COMMAND_IDENTIFIERS.PODCAST,
        title: 'Interview podcast',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-podcast-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'interview podcast' } },
      },
      {
        command: COMMAND_IDENTIFIERS.PODCAST,
        title: 'Educational podcast',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-podcast-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'educational podcast' } },
      },
      {
        command: COMMAND_IDENTIFIERS.PODCAST,
        title: 'Product spotlight',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-podcast-50.svg'),
        value: { context: { [COMMAND_IDENTIFIERS.POST_TYPE]: 'product spotlight' } },
      },
    ],
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Podcast type',
        identifier: COMMAND_IDENTIFIERS.POST_TYPE,
        type: 'select',
        values: PODCAST_POST_TYPE,
      },
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
  },
  [COMMANDS_TYPE.PRESS_RELEASE]: {
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Press release',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-commercial-50.svg'),
    description: 'Generate a press release',
    identifier: COMMAND_IDENTIFIERS.PRESS_RELEASE,
    slug: 'press-release',
    input: {
      label: 'Working title',
      type: 'char',
      description:
        'What should this press release be about? Examples: "new partnership", "Product launch for reading app"',
    },
    context: [
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
  },
  [COMMANDS_TYPE.JOB_DESCRIPTION]: {
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Job posting',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-resume-50.svg'),
    description: 'Generate a job posting',
    identifier: COMMAND_IDENTIFIERS.JOB_DESCRIPTION,
    slug: 'job-description',
    input: {
      label: 'Job title',
      type: 'char',
      description: 'Which job title do you want a posting for?',
    },
    context: [
      {
        label: 'Output type',
        identifier: COMMAND_IDENTIFIERS.OUTPUT_TYPE,
        type: 'radio',
        values: OUTPUT_TYPES,
      },
    ],
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
  },
  [COMMANDS_TYPE.EDITORIAL_BRIEF]: {
    contentTypes: ['Brief'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Editorial brief',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-training-50.svg'),
    description: 'Generate an editorial brief',
    identifier: COMMAND_IDENTIFIERS.EDITORIAL_BRIEF,
    slug: 'editorial-brief',
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: false,
      includeContentPieces: true,
    },
  },
  [COMMANDS_TYPE.SUMMARIZE]: {
    section: COMMAND_SECTIONS.EDIT,
    title: 'Summarize',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-summary-list-50.svg'),
    description: 'Extract the key points from your content.',
    identifier: COMMAND_IDENTIFIERS.SUMMARIZE,
    slug: 'content-summarize',
    autoRun: true,
    excludeContinue: true,
    input: {
      label: 'Content',
      type: 'text',
    },
  },
  [COMMANDS_TYPE.TRANSLATE]: {
    section: COMMAND_SECTIONS.EDIT,
    title: 'Translate',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-language-50.svg'),
    description: 'Convert your content into another language.',
    identifier: COMMAND_IDENTIFIERS.TRANSLATE,
    slug: 'content-translate',
    autoRun: true,
    items: [
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'English',
        value: { context: { language: 'english' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'French',
        value: { context: { language: 'french' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Spanish',
        value: { context: { language: 'spanish' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'German',
        value: { context: { language: 'german' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Dutch',
        value: { context: { language: 'dutch' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Italian',
        value: { context: { language: 'italian' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Portuguese',
        value: 'portuguese',
        code: 'pt',
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Danish',
        value: { context: { language: 'danish' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Finnish',
        value: { context: { language: 'finnish' } },
      },
      {
        command: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Norwegian',
        value: { context: { language: 'norwegian' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Swedish',
        value: { context: { language: 'swedish' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Hindi',
        value: { context: { language: 'hindi' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Indonesian',
        value: { context: { language: 'indonesian' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Mandarin',
        value: { context: { language: 'mandarin' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Thai',
        value: { context: { language: 'thai' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Vietnamese',
        value: { context: { language: 'vietnamese' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Japanese',
        value: { context: { language: 'japanese' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Korean',
        value: { context: { language: 'korean' } },
      },
      {
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        title: 'Slovak',
        value: { context: { language: 'slovak' } },
      },
    ],
    input: {
      label: 'Content',
      type: 'text',
    },
    context: [
      {
        label: 'Language',
        identifier: COMMAND_IDENTIFIERS.LANGUAGE,
        type: 'select',
        values: COMMAND_LANGUAGES,
      },
    ],
  },
  [COMMANDS_TYPE.PARAPHRASE]: {
    section: COMMAND_SECTIONS.EDIT,
    title: 'Improve',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-fantasy-50.svg'),
    description: 'Give content a refresh and make it look new.',
    identifier: COMMAND_IDENTIFIERS.PARAPHRASE,
    slug: 'content-improver',
    autoRun: true,
    input: {
      label: 'Content',
      type: 'text',
    },
  },
  [COMMANDS_TYPE.SOCIAL_POST]: {
    contentTypes: ['Postset', 'Post'],
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Social post',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-facebook-like-50.svg'),
    description: 'Convert your content into a social post.',
    identifier: COMMAND_IDENTIFIERS.SOCIAL_POST,
    slug: 'content-social-post',
    input: {
      label: 'Working title',
      type: 'char',
    },
    contextAssets: {
      required: true,
      includeContentPieces: true,
    },
    context: [
      {
        label: 'Audience (optional)',
        identifier: COMMAND_IDENTIFIERS.AUDIENCE,
        type: 'text',
        description: 'Provide personas, demographics, or any other relevant information.',
      },
      {
        label: 'Post purpose',
        identifier: COMMAND_IDENTIFIERS.POST_TYPE,
        type: 'select',
        values: SOCIAL_POST_PURPOSES,
      },
      {
        label: 'Tone',
        identifier: COMMAND_IDENTIFIERS.TONE,
        type: 'select',
        values: COMMAND_TONES_WITH_AUTODETECT,
      },
    ],
  },
  [COMMANDS_TYPE.TOPIC_QUESTIONS]: {
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Topic questions',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-question-mark-50.svg'),
    description: 'Get a list of frequently asked questions related to the topic of your choice.',
    identifier: COMMAND_IDENTIFIERS.TOPIC_QUESTIONS,
    slug: 'topic-questions',
    input: {
      label: 'Topic',
      placeholder: '',
      type: 'char',
    },
  },
  [COMMANDS_TYPE.TOPIC_PROSCONS]: {
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Topic pros & cons',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-change-50.svg'),
    description: 'Get a list of pros and cons related to the topic of your choice.',
    identifier: COMMAND_IDENTIFIERS.TOPIC_PROSCONS,
    slug: 'topic-proscons',
    input: {
      label: 'Topic',
      placeholder: '',
      type: 'char',
    },
  },
  [COMMANDS_TYPE.TOPIC_BLOG_TITLES]: {
    section: COMMAND_SECTIONS.DRAFT,
    title: 'Topic blog titles',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-cursor-50.svg'),
    description: 'Get catchy blog titles related to the topic of your choice.',
    identifier: COMMAND_IDENTIFIERS.TOPIC_BLOG_TITLES,
    slug: 'topic-blog-titles',
    input: {
      label: 'Topic',
      placeholder: 'Social media in 2023',
      type: 'char',
    },
  },
  [COMMANDS_TYPE.SPELLING]: {
    section: COMMAND_SECTIONS.EDIT,
    title: 'Fix spelling',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-double-tick-50.svg'),
    description: 'Fix spelling & grammar.',
    identifier: COMMAND_IDENTIFIERS.SPELLING,
    slug: 'spelling',
    autoRun: true,
    excludeContinue: true,
    input: {
      label: 'Content',
      type: 'text',
    },
  },
  [COMMANDS_TYPE.CHANGE_TONE]: {
    section: COMMAND_SECTIONS.EDIT,
    title: 'Change tone',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-tone-50.svg'),
    description: 'Change the tone of voice',
    identifier: COMMAND_IDENTIFIERS.CHANGE_TONE,
    slug: 'tone-voice',
    autoRun: true,
    excludeContinue: true,
    items: [
      {
        command: COMMAND_IDENTIFIERS.TONE,
        title: '💼 Professional',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-tone-50.svg'),
        value: { context: { tone: 'professional' } },
      },
      {
        command: COMMAND_IDENTIFIERS.TONE,
        title: '🧢 Casual',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-tone-50.svg'),
        value: { context: { tone: 'casual' } },
      },
      {
        command: COMMAND_IDENTIFIERS.TONE,
        title: '🎯 Straightforward',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-tone-50.svg'),
        value: { context: { tone: 'straightforward' } },
      },
      {
        command: COMMAND_IDENTIFIERS.TONE,
        title: '💪 Confident',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-tone-50.svg'),
        value: { context: { tone: 'confident' } },
      },
      {
        command: COMMAND_IDENTIFIERS.TONE,
        title: '😂 Humorous',
        icon: StoryChief.asset('images/components/assistant/commands/icons8-tone-50.svg'),
        value: { context: { tone: 'humorous' } },
      },
    ],
    input: {
      label: 'Content',
      type: 'text',
    },
    initialContext: {
      tone: 'professional',
    },
    context: [
      {
        label: 'Tone',
        identifier: COMMAND_IDENTIFIERS.TONE,
        type: 'select',
        values: COMMAND_TONES,
      },
    ],
  },
  [COMMANDS_TYPE.EXPLAIN]: {
    section: COMMAND_SECTIONS.EDIT,
    title: 'Explain this',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-info-50.svg'),
    description: 'Explain the provided content.',
    identifier: COMMAND_IDENTIFIERS.EXPLAIN,
    slug: 'explain',
    autoRun: true,
    input: {
      label: 'Content',
      type: 'text',
    },
  },
  [COMMANDS_TYPE.SIMPLIFY]: {
    section: COMMAND_SECTIONS.EDIT,
    title: 'Simplify',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-ok-50.svg'),
    description: 'Make your sentence as clear as possible.',
    identifier: COMMAND_IDENTIFIERS.SIMPLIFY,
    slug: 'simplify',
    autoRun: true,
    excludeContinue: true,
    input: {
      label: 'Content',
      type: 'text',
    },
  },
  [COMMANDS_TYPE.STORY_EXCERPT]: {
    section: COMMAND_SECTIONS.DRAFT,
    recommendationOnly: true,
    title: 'Article Excerpt',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-ok-50.svg'),
    description: 'Generate a clear excerpt for you article',
    identifier: COMMAND_IDENTIFIERS.STORY_EXCERPT,
    slug: 'story-excerpt',
    autoRun: true,
  },
  [COMMANDS_TYPE.STORY_META_DESCRIPTION]: {
    section: COMMAND_SECTIONS.DRAFT,
    recommendationOnly: true,
    title: 'Article Meta Description',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-ok-50.svg'),
    description: 'Generate a meta description for you article',
    identifier: COMMAND_IDENTIFIERS.STORY_META_DESCRIPTION,
    autoRun: true,
    excludeContinue: true,
  },
  [COMMANDS_TYPE.STORY_META_TITLE]: {
    section: COMMAND_SECTIONS.DRAFT,
    recommendationOnly: true,
    title: 'Article Meta Title',
    icon: StoryChief.asset('images/components/assistant/commands/icons8-ok-50.svg'),
    description: 'Generate a meta description for you article',
    identifier: COMMAND_IDENTIFIERS.STORY_META_TITLE,
    autoRun: true,
    excludeContinue: true,
  },
};

export const COMMANDS_OF_TYPE_EDIT = Object.values(COMMANDS).filter(
  (_c) => _c.section === COMMAND_SECTIONS.EDIT,
);

export const COMMANDS_OF_TYPE_WRITE = Object.values(COMMANDS).filter(
  (_c) => _c.section === COMMAND_SECTIONS.WRITE,
);

export default COMMANDS;
